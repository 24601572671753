import React from 'react';
import { Menu, Row, Col } from 'antd';
import logo from '../assets/images/logo.png'

import appStore from '../assets/images/app-store.png'
import googleStore from '../assets/images/google.png'
const Header = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='header'>
      <div className="container ">
        <Row className='menu-bar'>
          <Col lg={12}>
            <div className="logo">
              <img src={logo} />
            </div>
          </Col>
          <Col lg={12}>
            <Menu mode="horizontal" defaultSelectedKeys={['1']}>
              <Menu.Item key="1" onClick={() => handleScroll('about-us')}>
                About Us
              </Menu.Item>
              <Menu.Item key="2" onClick={() => handleScroll('services')}>
                Our Services
              </Menu.Item>
              <Menu.Item key="3" onClick={() => handleScroll('download')}>
                Download
              </Menu.Item>
              <Menu.Item key="4" onClick={() => handleScroll('testimonials')}>
                Testimonials
              </Menu.Item>
              <Menu.Item key="5" onClick={() => handleScroll('contact-us')}>
                Contact Us
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </div>
      <div className="banner">
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col lg={24}>
            <div>
              <img src={logo} className='pb-4 m-none' />
              <h1 className='text-white'> Don’t get in the MIDDL of 
              things… that’s our job.
                <br />
                Group pool your money or
                Direct Duel your buddy
              </h1>
              <p className='text-white pb-0 mb-0'> MIDDL is more than just a 
              money transfer app -- </p>
              <p className='text-white pt-0'>We Simplified group 
              payments and social betting. </p>
            </div>
            <Row gutter={24} justify={'center'}>
              <Col lg={3} xs={10} md={8} >
                <img src={appStore} className='w-100 mb-4'></img>
              </Col>
              <Col lg={3} xs={10} md={8}>
                <img src={googleStore} className='w-100'></img>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
